import gsap from 'gsap';
import Flickity from 'flickity-fade';
import axios from 'axios';
import LazyLoad from 'vanilla-lazyload';

const HTML = document.querySelector('html');
const nbSlides = 5;
const duration = 5000;
const hashtag = 'noelcuisine'
const slug = 'noelcuisine21'

class App {
  constructor() {
    /**
     * Lazyload
     */
    this.lazyload = new LazyLoad({
      // eslint-disable-next-line camelcase 
      elements_selector: '.js-lazy',
      threshold: 600,
      // eslint-disable-next-line camelcase
      callback_enter: ($el) => {
        if ($el.classList.contains('js-lazy-error-placeholder')) {
          $el.lazyLoadPlaceholder = $el.src;
        }
      },
      callback_loaded: (el) => {
        const figure = el.closest('figure');
        if (figure) figure.classList.add('img-is-loaded')
      },
      // eslint-disable-next-line camelcase
      callback_error: ($el) => {
        if ($el.lazyLoadPlaceholder) {
          $el.src = $el.lazyLoadPlaceholder;
        }
      },
    });

    if (document.querySelector('.mapping')) this.initMapping();
    this.autoload();
  }

  autoload() {
    const _ = this;
    const container = document.querySelector('[data-slug-client]');

    if (container) {
      const base = container.querySelector('.base').children[0];

      const params = new URLSearchParams({
        slug_client: container.dataset.slugClient,
        action: 'medias',
        full: true,
        limit: 20,
        created: container.dataset.last
      });

      axios(
        {
          method: 'get',
          url: `https://api.chiz.cool/get.php?${params.toString()}`
        }
      )
        .then(function (result) {
          if (result.data && result.data.length > 0) {
            result.data.forEach(event => {
              const elt = base.cloneNode(true);
              const img = elt.querySelector('img');
              img.setAttribute('data-src', event.url.replace('upload/', 'upload/w_540/'))
              const link = elt.querySelector('a');
              link.href = `/${container.dataset.slugClient}/${event.id}`

              container.prepend(elt)
              _.lazyload.update();
            })

            const last = result.data[result.data.length - 1];
            var t = last.created.split(/[- :]/);
            var d = new Date()
            d.setFullYear(t[0])
            d.setMonth(t[1] - 1)
            d.setDate(t[2])
            d.setHours(t[3])
            d.setMinutes(t[4])
            d.setSeconds(t[5])
            container.dataset.last = parseInt(d.getTime() / 1000);
          }

          setTimeout(() => {
            _.autoload();
          }, 10000)
        })
        .catch(function (error) {
          // handle error
          console.error(error);
          setTimeout(() => {
            _.autoload();
          }, 10000)
        })
    }
  }

  initMapping() {

    this.medias = [];
    this.last_instagram = 0;
    this.last_photo = '';

    const sliderSecondary = document.querySelector('.secondary-media .slider')
    this.sliderSecondary = new Flickity(sliderSecondary, {
      fade: true,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: 500,
      wrapAround: true,
      selectedAttraction: 0.5,
      friction: 1
    });

    const sliderThird = document.querySelector('.third-media .slider')
    this.sliderThird = new Flickity(sliderThird, {
      fade: true,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: 500,
      wrapAround: true,
      selectedAttraction: 0.5,
      friction: 1
    });

    const sliderFourth = document.querySelector('.fourth-media .slider')
    this.sliderFourth = new Flickity(sliderFourth, {
      fade: true,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: 500,
      wrapAround: true,
      selectedAttraction: 0.5,
      friction: 1
    });

    this.loop();
  }

  loop() {
    const params = new URLSearchParams({
      action: 'medias',
      slug_event: slug
    });

    // this.instagram();

    axios({
      method: 'get',
      url: `./ajax.php?${params.toString()}`
    })
      .then((response) => {
        this.medias = response.data;
        this.buildMainMedia();

        setTimeout(() => {
          this.loop();
        }, duration)
      })
      .catch(() => {
        setTimeout(() => {
          this.loop();
        }, duration)
      })
  }

  buildMainMedia() {
    const mainMedia = document.querySelector('.main-media')
    const oldImg = mainMedia.querySelector('img')

    const first = this.medias.shift();

    if (first.url != this.last_photo) {
      const img = document.createElement('img')
      img.classList.add('absolute', 'top-1/2', 'left-1/2', 'transform-gpu', '-translate-x-1/2', '-translate-y-1/2', 'w-full', 'h-full', 'object-cover')
      img.src = first.url
      img.onload = function () {
        mainMedia.prepend(img)
        gsap.to(oldImg, { autoAlpha: 0, onComplete: () => { if (oldImg) oldImg.remove() } });
      }

      this.buildSecondarySlider(this.sliderSecondary, nbSlides);
      this.buildSecondarySlider(this.sliderThird, nbSlides, nbSlides);
      this.buildSecondarySlider(this.sliderFourth, nbSlides, nbSlides * 2);

      this.last_photo = first.url;
    }
  }

  buildSecondarySlider(slider, nb, offset = 0) {
    // Clear old slider
    const oldElements = slider.getCellElements()

    // Get 5 next medias
    const medias = this.medias.slice(offset, nb + offset);
    if (medias) {
      let mediasElts = [];
      medias.forEach(media => {
        const img = document.createElement('img');
        img.classList.add('w-full', 'h-full', 'object-cover')
        img.src = media.url.replace('upload/', 'upload/w_540/');
        mediasElts.push(img);
      })
      slider.append(mediasElts)

      if (oldElements) slider.remove(oldElements);
    }
  }

  instagram() {
    const instagramContainer = document.querySelector('.instagram-feed .img');
    const oldImg = instagramContainer.querySelector('img')

    const params = new URLSearchParams({
      action: 'instagram',
      hashtag: hashtag,
    });

    axios(
      {
        method: 'get',
        url: `./ajax.php?${params.toString()}`
      }
    )
      .then((response) => {
        if (response && response.data.graphql && response.data.graphql.hashtag && response.data.graphql.hashtag.edge_hashtag_to_media) {
          const medias = response.data.graphql.hashtag.edge_hashtag_to_media.edges;
          const last_post = medias.shift();

          if (this.last_instagram != last_post.node.id) {
            this.last_instagram = last_post.node.id

            const img = document.createElement('img')
            img.classList.add('absolute', 'top-1/2', 'left-1/2', 'transform-gpu', '-translate-x-1/2', '-translate-y-1/2', 'w-full', 'h-full', 'object-cover')
            img.src = `/static/uploads/social-insta-${last_post.node.id}.png`;
            img.onload = function () {
              instagramContainer.prepend(img)
              gsap.to(oldImg, { autoAlpha: 0, onComplete: () => { if (oldImg) oldImg.remove() } });
            }
          }
        }
      })
  }
}


window.addEventListener('load', () => {
  new App();
})